import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {DataStorageService} from '../Utils/data-storage.service';
import {SharedDataService} from '../Utils/sharedData.service';
import {BillStorageService} from '../Utils/bill-storage.service';
import {NotificationsService} from 'angular2-notifications';


@Component({
  selector: 'app-generate-bill',
  templateUrl: './generate-bill.component.html',
  styleUrls: ['./generate-bill.component.css']
})
export class GenerateBillComponent implements OnInit {

  constructor(private dataService: DataStorageService,
              private route: ActivatedRoute,
              private router: Router,
              private sharedDataService: SharedDataService,
              private billService: BillStorageService,
              private _service : NotificationsService) { }

  public names;
  bsRangeValue;
  maxDate= new Date();
  bills;
  displayTable = false;
  displayBills = false;
  displayLRs = false;
  pendingBills;
  pendingLRs;

  public options = {
    position: ["bottom", "right"],
    timeOut: 1000,
    lastOnBottom: true
  }

  @ViewChild('pname') pname:any;
  @ViewChild('duration') dur: any;

  ngOnInit() {
    this.dataService.getNames()
      .subscribe(
        (response) => {
          this.names = response;
          this.names.sort();
        }, (err) => {
          console.log(err);
        }
      );
  }

  downloadPDf(){
    let data= [];
    data['range'] = {
      startDate: this.bsRangeValue[0],
      endDate: this.bsRangeValue[1]
    }
    data['name'] = this.pname.nativeElement.value;
    this.sharedDataService.setData(data);
    this.router.navigate(['../','print'], {relativeTo: this.route})
  }

  showBills() {
    this.dataService.getNonGeneratedEntries(this.pname.nativeElement.value, this.bsRangeValue[0], this.bsRangeValue[1])
      .subscribe(
        (response) => {
          this.bills = response;
          if (!this.bills.length) {
            this.displayTable = false;
            this._service.warn(
              'Message',
              'No un billed entries found',
              {
                timeOut: 1000,
                showProgressBar: true,
                pauseOnHover: false,
                clickToClose: false,
                maxLength: 10
              }
            )
          }
          else {
            this.displayTable = true;
          }
        });
  }

  getPendingBills() {
    if(!this.pendingBills) {
      this.billService.getUnpaidBillFullDetails()
        .subscribe(
          (response) => {
            this.pendingBills = response;
            this.pendingBills = this.pendingBills.sort((a, b) => {
              if (a.billDate < b.billDate)
                return -1;
              if (a.billDate > b.billDate)
                return 1;
              return 0;
            });
            this.displayBills = true;
          }, (err) => {
            this._service.error(
              'Message',
              err.message,
              {
                timeOut: 1000,
                showProgressBar: true,
                pauseOnHover: false,
                clickToClose: false,
                maxLength: 10
              }
            )
          }
        )
    }
    else this.displayBills = true;
  }


  getPendingLRs() {
    if(!this.pendingLRs) {
      this.dataService.getUnpaidLRFullDetails()
        .subscribe(
          (response) => {
            this.pendingLRs = response;
            this.pendingLRs = this.pendingLRs.sort((a, b) => {
              if (a.billDate < b.billDate)
                return -1;
              if (a.billDate > b.billDate)
                return 1;
              return 0;
            });
            this.displayLRs = true;
          }, (err) => {
            this._service.error(
              'Message',
              err.message,
              {
                timeOut: 1000,
                showProgressBar: true,
                pauseOnHover: false,
                clickToClose: false,
                maxLength: 10
              }
            )
          }
        )
    }
    else this.displayLRs = true;
  }

  getPendingDays(date) {
    let diff =  Math.floor(( Date.parse(new Date().toDateString()) - Date.parse(date) ) / 86400000)
    return diff + " days";
  }

  getColor(date) {
    let diff =  Math.floor(( Date.parse(new Date().toDateString()) - Date.parse(date) ) / 86400000)
    if(diff > 30) return 'red';
    return 'green';
  }
 }
