import {Component, OnInit, ViewChild} from '@angular/core';
import {DataStorageService} from '../Utils/data-storage.service';

import {Router} from '@angular/router';
import {ExpenseStorageService} from '../Utils/expense-storage.service';
import {ExpenseModal} from '../Utils/expense.modal';

@Component({
  selector: 'app-daily-report',
  templateUrl: './daily-report.component.html',
  styleUrls: ['./daily-report.component.css']
})
export class DailyReportComponent implements OnInit {

  maxDate = new Date();
  dailyReports = [];
  model: any = {};
  showTable = false;
  totalAmount = 0;
  cashAmount = 0;
  dieselAmount = 0;
  miscAmount = 0;
  loadAmount = 0;
  balanceAmount = 0;
  reportGeneateDate;
  expenseUpdate = false;
  showExpenseForm = false;
  id = '';

  @ViewChild('reportDate') reportDate: any;
  @ViewChild('expense') expense: any;
  endDate;

  constructor(private dataStorageService: DataStorageService,
              private router: Router,
              private expenseStorageService: ExpenseStorageService) { }

  ngOnInit() {
  }


  onSubmit() {
    this.endDate = new Date(this.reportDate.nativeElement.value);
    let date = this.endDate;
    // date.setDate(this.endDate.getDate());
    date.setHours(0,0,0,0);
    const expenseDate = date.toISOString();
    this.endDate.setDate(this.endDate.getDate() + 1);
    this.dataStorageService.getDailyReport(this.reportDate.nativeElement.value, this.endDate)
      .subscribe((entries: Array<any>) => {
        this.expenseStorageService.getExpense(expenseDate)
          .subscribe((expense: Array<any>) => {
            this.totalAmount = 0;
            this.cashAmount = 0;
            if (expense[0] && (expense[0]['Misc'] || expense[0]['load'] || expense[0]['Diesel'])) {
              this.expenseUpdate = true;
              this.id =  expense[0]['_id'];
            }
            this.miscAmount = expense[0] ? expense[0]['Misc'] : 0;
            this.loadAmount = expense[0] ? expense[0]['load'] : 0;
            this.dieselAmount = expense[0] ? expense[0]['Diesel'] : 0;
            this.balanceAmount = 0;
            this.dailyReports = [];
            let paid = [];
            let unpaid = [];
            this.reportGeneateDate = this.reportDate.nativeElement.value;
            entries.forEach(report => {
              this.totalAmount += report.amount;
              if(report.billNo === -1) {
                this.cashAmount += report.amount;
                paid.push(report);
              } else {
                unpaid.push(report);
              }
            });
            this.dailyReports = paid.concat(unpaid);
            this.balanceAmount = this.totalAmount - (this.dieselAmount + this.miscAmount + this.loadAmount);
            this.showTable = true;
          });
      });
  }


  enableExpenseForm() {
    this.model = {
      'diesel': this.dieselAmount,
      'misc': this.miscAmount,
      'load': this.loadAmount
    };
    this.showExpenseForm = true;
  }

  onExpenseSubmit() {
    this.loadAmount = this.model.load;
    this.miscAmount = this.model.misc;
    this.dieselAmount = this.model.diesel;
    this.balanceAmount = this.totalAmount - (this.dieselAmount + this.dieselAmount + this.loadAmount);
    const date = new Date();
    date.setDate(this.endDate.getDate() - 1);
    date.setHours(0,0,0,0);
    const expensedate = date.toISOString();
    const obj: ExpenseModal = {
      date: expensedate,
      Diesel: this.model.diesel,
      load: this.model.load,
      Misc: this.model.misc
    };
    if (this.expenseUpdate) {
      this.expenseStorageService.updateExpense(obj,this.id)
        .subscribe(res => {
          this.showExpenseForm = false;
        });
    } else {
      this.expenseStorageService.storeExpense(obj)
        .subscribe(res => {
          this.showExpenseForm = false;
        })
    }
  }

  getBgColor(billNo) {
    return billNo === -1 ? 'green' : '';
  }

  downloadPdf() {
    let date:any = new Date(this.reportGeneateDate.toString());
    date = date.getTime();
    this.endDate = this.endDate.getTime();
    const url = `${window.location.origin}/printreport/${date}/${this.endDate}`;
    window.open(url, '_blank');
  }
}
