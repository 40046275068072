import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Params} from '@angular/router';
import {DataStorageService} from '../Utils/data-storage.service';
import {ExpenseStorageService} from '../Utils/expense-storage.service';

@Component({
  selector: 'app-print-report',
  templateUrl: './print-report.component.html',
  styleUrls: ['./print-report.component.css']
})
export class PrintReportComponent implements OnInit {

  date: any;
  endDate: any;
  dailyReports = [];
  totalAmount = 0;
  cashAmount = 0;
  dieselAmount = 0;
  miscAmount = 0;
  loadAmount = 0;
  balanceAmount = 0;

  constructor(private route: ActivatedRoute,
              private dataStorageService: DataStorageService,
              private expenseStorageService: ExpenseStorageService) { }

  ngOnInit() {
    this.route.params
      .subscribe(
        (params: Params) => {
          this.date = params['date'];
          this.endDate = params['endDate'];
          this.date = new Date(parseInt(this.date));
          this.endDate = new Date(parseInt(this.endDate));
          this.getInitialData();
        }
      );
    document.getElementsByClassName('topbar')[0].setAttribute('style', 'display:none');
    document.getElementsByClassName('left-sidebar')[0].setAttribute('style', 'display:none');
    document.getElementsByClassName('page-wrapper')[0].setAttribute('style', 'margin-left:0');
  }

  getInitialData() {
    let date = new Date();
    date.setDate(this.date.getDate());
    date.setHours(0,0,0,0);
    const expensedate = date.toISOString();
    this.dataStorageService.getDailyReport(this.date, this.endDate)
      .subscribe((entries: Array<any>) => {
        this.expenseStorageService.getExpense(expensedate)
          .subscribe((expense: Array<any>) => {
            this.totalAmount = 0;
            this.cashAmount = 0;
            this.miscAmount = expense[0] ? expense[0]['Misc'] : 0;
            this.loadAmount = expense[0] ? expense[0]['load'] : 0;
            this.dieselAmount = expense[0] ? expense[0]['Diesel'] : 0;
            this.balanceAmount = 0;
            this.dailyReports = entries;
            this.dailyReports.forEach(report => {
              this.totalAmount += report.amount;
              if (report.billNo === -1) this.cashAmount += report.amount;
            });
            this.balanceAmount = this.totalAmount - (this.dieselAmount + this.dieselAmount + this.loadAmount);
          });
      });
  }

}
