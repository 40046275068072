import {Component, OnInit, ViewChild} from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  /*lat: number = 18.974224;
  lng: number =  72.846074;
  zoom: number = 17;

  @ViewChild('map') map: AgmMap;*/


  constructor() { }

  ngOnInit() {

  }

  /*resizeMap() {
    //this.map.triggerResize();
    var mapProp = {
      center: new google.maps.LatLng(18.5793, 73.8143),
      zoom: 15,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    };
    this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProp);
  }*/
}
