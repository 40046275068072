// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {

  // apiUrl: "http://localhost:8080/",
  apiUrl: "",
  production: false
};



/* var cursor = db.getCollection('entries').find({"billNo": -1, "paymentDetails.paymentDate": {"$exists": true, "$type": 2 }});
while (cursor.hasNext()) {
    var doc = cursor.next();
    db.getCollection('entries').update(
        {"_id" : doc._id},
        {"$set" : {"paymentDetails.paymentDate" : new ISODate(doc.paymentDetails.paymentDate)}}
    )
}; */
