import {Component, OnInit, ViewChild} from '@angular/core';
import {PaymentStorageService} from '../Utils/payment-storage.service';
import {DataStorageService} from '../Utils/data-storage.service';
import {DatePipe} from '@angular/common';
import {NotificationsService} from 'angular2-notifications';

@Component({
  selector: 'app-pending-bill',
  templateUrl: './pending-bill.component.html',
  styleUrls: ['./pending-bill.component.css']
})
export class PendingBillComponent implements OnInit {

  constructor(private paymentService: PaymentStorageService,
              private dataService: DataStorageService,
              private _service: NotificationsService) { }

  showTable = false;
  showCalendar = false;
  maxDate = new Date();
  bsRangeValue;
  payments = [];
  public table = $('#billTable').DataTable();
  public options = {
    position: ["bottom", "right"],
    timeOut: 1000,
    lastOnBottom: true
  }

  billViewOptions = [
    { 'id': 1, 'value': 'Current Month'},
    { 'id': 2, 'value': 'Last Month & Current Month'},
    { 'id': 3, 'value': 'Last 6 Months'},
    { 'id': 4, 'value': 'Select Time Period'},
  ];

  ngOnInit() {
  }

  @ViewChild('filter') filter:any;

  filterChanged() {
    this.showCalendar = this.filter.nativeElement.value === "4";
  }

  searchPayments() {
    let val = this.filter.nativeElement.value;
    this.payments = [];
    let startDate = new Date(new Date().setHours(0,0,0,0));
    let endDate = new Date();
    endDate.setDate(endDate.getDate() + 1);
    endDate.setHours(0,0,0,0);
    startDate = new Date(startDate.getFullYear(), startDate.getMonth(), 1);
    if (val === '2') {
      startDate.setMonth(startDate.getMonth() - 1);
    } else if (val === '3') {
      startDate.setMonth(startDate.getMonth() - 6);
    } else if(val === '4'){
      startDate = new Date(this.bsRangeValue[0].setHours(0,0,0,0));
      endDate = new Date(this.bsRangeValue[1].setHours(23,59,59,0));
    }

    this.paymentService.getAllPayments(startDate.toDateString(), endDate.toDateString())
      .subscribe(
        (response: any) => {
          if(response.length) {
            response.forEach(res => {
              res.paymentDetails.forEach(item => {
                if(new Date(item.date) >= startDate && new Date(item.date) < endDate) {
                  const mode = item.mode === 'Cash' ? `Cash` : `Cheque (${item.bankName}, ${item.chequeNumber})`;
                  this.payments.push({
                      'name': res.name,
                      'amount': item.amount,
                      'mode': mode,
                      'date': item.date
                    });
                }
              })
            })
          }

          this.dataService.getPaidOnBooking(startDate.toDateString(), endDate.toDateString())
            .subscribe(
              (response: any) => {
                if(response.length) {
                  response.forEach(res => {
                    const pDetail = res.paymentDetails;
                    const mode = res.paymentDetails.mode === 'Cash' ? `On Booking - Cash`
                      : `On Booking - Cheque (${pDetail.bankName}, ${pDetail.chequeNumber})`;
                    this.payments.push({
                        'name': res.name,
                        'amount': res.amount,
                        'mode': mode,
                        'date': res.date
                      }
                    )
                  })
                }
                this.drawTable(this.payments);
              }
            )
        }
      )
  }

  drawTable(payments) {
    if (!payments.length) {
      this._service.warn(
        'Message',
        'No Payments found',
        {
          timeOut: 1000,
          showProgressBar: true,
          pauseOnHover: false,
          clickToClose: false,
          maxLength: 10
        }
      )
    }
    else {
      this.table.destroy();
      this.showTable = true;
      this.table = $('#billTable').DataTable({
        'data': payments,
        'order': [[ 0, 'desc' ]],
        'columns': [
          {
            title: 'Date',
            'data': function (data) {
              return new Date(data.date);
            },
            'render': function (data) {
              return `<span style="display: none">${Date.parse(data)} </span><span>${new DatePipe('en-US').transform(data, 'dd/MM/yyyy')}</span>`
            }
          },
          {
            title: 'Party Name',
            'data': 'name'
          },
          {
            title: 'Payment Mode',
            'data': 'mode'
          },
          {
            title: 'Amount (Rs.)',
            'data': 'amount'
          }
        ],
        footerCallback: function ( row, data, start, end, display ) {
          let api = this.api();
          let pageTotal = api
            .column( 3, { page: 'current'} )
            .data()
            .reduce( function (a, b) {
              return a + b;
            }, 0 );

          let total = api
            .column( 3 )
            .data()
            .reduce( function (a, b) {
              return a + b;
            }, 0 );

          // Update footer
          $( api.column( 3 ).footer() ).html(
            pageTotal + ' (Total: ' + total + ')'
          );
        }
      });
    }
  }

}
