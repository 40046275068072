import {Injectable} from '@angular/core';
import {AuthService} from '../Core/auth/auth.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {ExpenseModal} from './expense.modal';

@Injectable()
export class ExpenseStorageService {

  apiUrl = environment.apiUrl;

  constructor(private authService: AuthService,
              private http: HttpClient) {
  }

  getExpense(date: string) {
    if(true) {
      let params = new HttpParams();
      params = params.append('date', date);
      return this.http.get(this.apiUrl + 'expense/expense', {params: params});
    }
  }

  storeExpense(expense: ExpenseModal) {
    if(this.authService.isAuthenticated()) {
      return this.http.post(this.apiUrl + 'expense/addExpense', {expense});
    }
  }


  updateExpense(expense: ExpenseModal, id: string ) {
    if(this.authService.isAuthenticated()) {
      return this.http.put(this.apiUrl + 'expense/updateExpense', {expense, id});
    }
  }
}
