import {Injectable} from '@angular/core';
import {BillModal} from './bill.modal';
import {AuthService} from '../Core/auth/auth.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment';

@Injectable()
export class BillStorageService {

  apiUrl = environment.apiUrl;

  constructor(private authService: AuthService,
              private http: HttpClient) {

  }

  getTotalBill(date) {
    if(this.authService.isAuthenticated()) {
      let params = new HttpParams();
      params = params.append('date', date);
      return this.http.get(this.apiUrl + 'bills/totalBills', {params: params});
    }
  }

  storeBill(bill: BillModal) {
    if(this.authService.isAuthenticated()) {
      return this.http.post(this.apiUrl + 'bills/bills', bill);
    }
  }

  getBill(billNo: string, LRNo: number) {
    if(true) {
      let params = new HttpParams();
      params = params.append('billNo', billNo);
      params = params.append('LRNo', LRNo.toString());
      return this.http.get(this.apiUrl + 'bills/bill', {params: params});
    }
  }

  deleteBill(billNo: number) {
    if(this.authService.isAuthenticated()) {
      let params = new HttpParams();
      params = params.append('billNo', billNo.toString());
      return this.http.delete(this.apiUrl + 'bills/entry', {params: params});
    }
  }

  getUnPaidBills(name: string) {
    if(this.authService.isAuthenticated()) {
      let params = new HttpParams();
      params = params.append('name', name);
      return this.http.get(this.apiUrl + 'bills/unpaidBill', {params: params});
    }
  }

  getUnpaidBillFullDetails() {
    if(this.authService.isAuthenticated()) {
      return this.http.get(this.apiUrl + 'bills/unpaidBillFullDetails');
    }
  }

  updateBalance(billNos: any , billDetails: any ) {
    if(this.authService.isAuthenticated()) {
      return this.http.put(this.apiUrl + 'bills/update', {billNos, billDetails});
    }
  }
}
