import {NgModule} from "@angular/core";
import {AppRouterModule} from "../app-router.module";
import { LoginComponent } from './auth/login/login.component';
import {AuthService} from "./auth/auth.service";
import {CommonModule} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {DataStorageService} from "../Utils/data-storage.service";
import {AuthGaurdService} from "./auth/authGaurd.service";
import {SharedDataService} from '../Utils/sharedData.service';
import {BillStorageService} from '../Utils/bill-storage.service';
import {PaymentStorageService} from '../Utils/payment-storage.service';
import {NavigationComponent} from './shared/header-navigation/navigation.component';
import {SidebarComponent} from './shared/sidebar/sidebar.component';
import {SIDEBAR_TOGGLE_DIRECTIVES} from './shared/sidebar.directive';
import {ExpenseStorageService} from '../Utils/expense-storage.service';

@NgModule ({
  declarations: [
    NavigationComponent,
    LoginComponent,
    SidebarComponent,
    SIDEBAR_TOGGLE_DIRECTIVES
  ],
  imports: [
    FormsModule,
    AppRouterModule,
    CommonModule
  ],
  exports: [
    NavigationComponent,
    AppRouterModule,
    CommonModule,
    SidebarComponent,
    SIDEBAR_TOGGLE_DIRECTIVES
  ],
  providers: [
    AuthService,
    DataStorageService,
    BillStorageService,
    PaymentStorageService,
    AuthGaurdService,
    SharedDataService,
    ExpenseStorageService
  ]
})

export class CoreModule {}
