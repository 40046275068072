import {Component, OnInit, ViewChild} from '@angular/core';
import {DataStorageService} from '../Utils/data-storage.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {DatePipe} from '@angular/common';
import {BillStorageService} from '../Utils/bill-storage.service';
import {PaymentStorageService} from '../Utils/payment-storage.service';

import * as cloneDeep  from 'lodash/cloneDeep'
import {NotificationsService} from 'angular2-notifications';

var $ = require('jquery');
var dt = require('datatables.net');

@Component({
  selector: 'app-view-balance',
  templateUrl: './view-balance.component.html',
  styleUrls: ['./view-balance.component.css']
})

export class ViewBalanceComponent implements OnInit {
  waiveOff: string;
  advance: string;

  paymentDetails;
  names;
  showResult = false;
  totalAmount:number = 0;
  balanceAmount:number = 0;
  partyName:string = '';
  addPayment: FormGroup;
  displayTable = false;
  maxDate = new Date();
  totalBalance = 0;
  totalUnBilledBalance = 0;
  unPaidBills;
  showPaymentDetails = false;
  amountToAdjust = 0;
  totalAmountSelected = 0;
  paidBills = [];
  billNos = [];
  unSelectedBills = [];
  showWaiveOptions = false;
  showAdvanceOptions = false;
  disableSubmit = true;
  showCalendar = false;
  bsRangeValue;
  startDate = new Date();
  endDate = new Date();

  public options = {
    position: ["bottom", "right"],
    timeOut: 1000,
    lastOnBottom: true
  }

  public table = $('#billTable').DataTable();


  @ViewChild('pname') pName:any;
  @ViewChild('filter') filter:any;


  constructor(private billService: BillStorageService,
              private dataService: DataStorageService,
              private paymentService: PaymentStorageService,
              private _service: NotificationsService) { }

  pTypes = ['Cash','Cheque'];

  paymentViewOptions = [
    { 'id': 1, 'value': 'All'},
    { 'id': 2, 'value': 'Last 6 Months'},
    { 'id': 3, 'value': 'Last 12 Months'},
    { 'id': 4, 'value': 'Select Time Period'},
  ];


  ngOnInit() {
    this.dataService.getNames()
      .subscribe(
        (response) => {
          this.names = response;
          this.names.sort();
          this.filter.nativeElement.value = '2';
          this.formInit();
        }, (err) => {
          console.log(err);
        }
      );
  }

  resetValues() {
    this.showPaymentDetails = false;
    this.amountToAdjust = 0;
    this.totalAmountSelected = 0;
    this.paidBills = [];
    this.billNos = [];
    this.unSelectedBills = [];
    this.showWaiveOptions = false;
    this.showAdvanceOptions = false;
    this.disableSubmit = true;
    this.totalUnBilledBalance = 0;
    this.totalBalance = 0;
  }

  filterChanged() {
    this.showCalendar = this.filter.nativeElement.value === "4";
  }

  filterPayments() {
    let val = this.filter.nativeElement.value;
    let elements = this.paymentDetails;
    let startDate = new Date(new Date().setHours(0,0,0,0));
    let endDate = new Date();
    startDate = new Date(startDate.getFullYear(), startDate.getMonth(), 1);
    if(val === '1') {
      this.startDate = this.endDate;
    } else if (val === '2') {
      startDate.setMonth(startDate.getMonth() - 6);
      elements = elements.filter(ele => {
        return new Date(ele.date) >= startDate;
      })
    } else if (val === '3') {
      startDate.setMonth(startDate.getMonth() - 12);
      elements = elements.filter(ele => {
        return new Date(ele.date) >= startDate;
      })
    } else {
      startDate = new Date(this.bsRangeValue[0].setHours(0,0,0,0));
      endDate = new Date(this.bsRangeValue[1].setHours(23,59,59,0));
      elements = elements.filter(ele => {
        return new Date(ele.date) >= startDate && new Date(ele.date) <= endDate;
      })
    }
    if(elements.length && val !== '1') {
      this.startDate = startDate;
      this.endDate = endDate;
    }
    this.drawTable(elements);
  }

   adjustBalance() {
      this.amountToAdjust = 0;
      this.totalAmountSelected = 0;
      for(let bill in this.unPaidBills) {
        if (this.unPaidBills[bill].checked) {
          this.totalAmountSelected += this.unPaidBills[bill].balanceAmount;
          if(this.paidBills.indexOf(this.unPaidBills[bill].billNo) === -1)
          this.paidBills.push(this.unPaidBills[bill].billNo);
          if(this.unSelectedBills.indexOf(this.unPaidBills[bill].billNo) !== -1)
          this.unSelectedBills.splice(this.unSelectedBills.indexOf(this.unPaidBills[bill].billNo),  1);
        } else {
          if(this.paidBills.indexOf(this.unPaidBills[bill].billNo) !== -1)
          this.paidBills.splice(this.paidBills.indexOf(this.unPaidBills[bill].billNo),  1);
          if(this.unSelectedBills.indexOf(this.unPaidBills[bill].billNo) === -1)
          this.unSelectedBills.push(this.unPaidBills[bill].billNo);
        }
      }
      this.paidBills.sort();
      this.unSelectedBills.sort();
      this.amountToAdjust = this.addPayment.value.amount - this.totalAmountSelected;
       if(this.amountToAdjust === 0 || this.paidBills.length === 0) {
         this.showWaiveOptions= false;
         this.showAdvanceOptions = false;
       }else {
        this.disableSubmit = true;
        if(this.amountToAdjust < 0 ) {
          this.showWaiveOptions = true;
          this.showAdvanceOptions = false;
        } else {
          this.showAdvanceOptions = true;
          this.showWaiveOptions = false;
        }
      }
      if(!this.amountToAdjust) this.disableSubmit = false;
   }

  filterResults() {
    this.partyName = this.pName.nativeElement.value;
    this.resetValues();
    this.paymentService.getPayments(this.partyName)
      .subscribe(
        (response) => {
          if(response) {
            this.paymentDetails = response;
            this.filterPayments();
          }
          this.dataService.nonGeneratedEntriesAmount(this.partyName)
            .subscribe(
              (response: any) => {
                let unBilledEntries = response;
                if(unBilledEntries.length) {
                  unBilledEntries.forEach(entry => {
                    this.totalUnBilledBalance += entry.amount;
                  })
                }
              }
            )
          this.billService.getUnPaidBills(this.partyName)
            .subscribe(
              (response) => {
                this.totalBalance = 0;
                this.unPaidBills = response;
                if(this.unPaidBills.length) {
                  for(let bill in this.unPaidBills) {
                    this.totalBalance += response[bill].balanceAmount;
                    this.unSelectedBills.push(response[bill].billNo);
                  }
                  this.unSelectedBills.sort();
                  this.billNos = cloneDeep(this.unSelectedBills);
                } else {
                  this.disableSubmit = false;
                }
                this.showResult = true;
              }, (err) => {
                this._service.error(
                  'Message',
                  err,
                  {
                    timeOut: 1000,
                    showProgressBar: true,
                    pauseOnHover: false,
                    clickToClose: false,
                    maxLength: 10
                  }
                )
              });
        }, (err) => {
          this._service.error(
            'Message',
            err,
            {
              timeOut: 1000,
              showProgressBar: true,
              pauseOnHover: false,
              clickToClose: false,
              maxLength: 10
            }
          )
        }
      );
  }

  addPayments() {
    this.showPaymentDetails = true;
    this.showWaiveOptions = false;
    this.showAdvanceOptions = false;
    this.waiveOff = '';
    this.advance = '';
    this.totalAmountSelected = 0;
    this.unPaidBills.forEach(bill => {
      bill.checked = false;
    })
  }

  onSubmit() {

    if(this.unPaidBills.length) {
      let bills = cloneDeep(this.unPaidBills);
      let unmatchedAmount = this.amountToAdjust;
      if(this.amountToAdjust) {
        if(this.amountToAdjust > 0 ) {
          if(this.advance === 'adjust') {
            bills = bills.filter(bill => {
              if(bill.billNo === this.unSelectedBills[0]) {
                bill.balanceAmount -= this.amountToAdjust;
              } else {
                if(this.paidBills.indexOf(bill.billNo) !== -1) bill.balanceAmount = 0;
              }
              return bill;
            });
            unmatchedAmount = 0;
          } else {
            bills = bills.filter(bill => {
              if(this.paidBills.indexOf(bill.billNo) !== -1) bill.balanceAmount = 0;
              return bill;
            })
          }
        } else {
          if(this.waiveOff === 'waiveOff') {
            bills = bills.filter(bill => {
              if(bill.billNo === this.paidBills[this.paidBills.length - 1]) {
                bill.balanceAmount = 0;
                bill.waivedOff = Math.abs(this.amountToAdjust);
              } else {
                if(this.paidBills.indexOf(bill.billNo) !== -1) bill.balanceAmount = 0;
              }
              return bill;
            })
          } else {
            bills = bills.filter(bill => {
              if(bill.billNo === this.paidBills[this.paidBills.length - 1]) {
                bill.balanceAmount = Math.abs(this.amountToAdjust);
              } else {
                if(this.paidBills.indexOf(bill.billNo) !== -1) bill.balanceAmount = 0;
              }
              return bill;
            })
          }
          unmatchedAmount = 0;
        }
      } else if(this.amountToAdjust === 0 && this.paidBills.length !== 0) {
        bills = bills.filter(bill => {
          if(this.paidBills.indexOf(bill.billNo) !== -1) bill.balanceAmount = 0;
          return bill;
        })
      }

      this.paymentService.addPayment(this.partyName, this.addPayment.value, unmatchedAmount)
        .subscribe(
          (response) => {
            this.billService.updateBalance(this.billNos, bills)
              .subscribe(
                (response) => {
                  this._service.success(
                    'Message',
                    'Succesfully Added',
                    {
                      timeOut: 5000,
                      showProgressBar: true,
                      pauseOnHover: false,
                      clickToClose: false,
                      maxLength: 10
                    }
                  )
                  this.filterResults();
                }, (err) => {
                  console.log(err);
                }
              )
          }, (err) => {
            console.log(err);
          }
        )
      //console.log(this, bills);
    } else {
      this.addPayment.value.mode = "Advance / " + this.addPayment.value.mode;
     this.paymentService.addPayment(this.partyName, this.addPayment.value, this.addPayment.value.amount)
        .subscribe(
          (response) => {
            this._service.success(
              'Message',
              'Succesfully Added',
              {
                timeOut: 1000,
                showProgressBar: true,
                pauseOnHover: false,
                clickToClose: false,
                maxLength: 10
              }
            )
            this.filterResults();
          }, (err) => {
            this._service.error(
              'Message',
              err,
              {
                timeOut: 1000,
                showProgressBar: true,
                pauseOnHover: false,
                clickToClose: false,
                maxLength: 10
              }
            )
          }
        )
    }
    this.paymentDetails.push(this.addPayment.value);
    this.drawTable(this.paymentDetails);
    this.closeDialog();
  }

  closeDialog() {
    this.formInit();
    this.showPaymentDetails = false;
  }

  drawTable(payments) {
    if (!payments.length) {
      this._service.warn(
        'Message',
        'No Payments found',
        {
          timeOut: 1000,
          showProgressBar: true,
          pauseOnHover: false,
          clickToClose: false,
          maxLength: 10
        }
      )
    }
    else {
      this.table.destroy();
      this.displayTable = true;
      this.table = $('#billTable').DataTable({
        'data': payments,
        'order': [[ 0, 'desc' ]],
        'columns': [
          {
            title: 'Date',
            'data': function (data) {
              return new Date(data.date);
            },
            'render': function (data) {
              return `<span style="display: none">${Date.parse(data)} </span><span>${new DatePipe('en-US').transform(data, 'dd/MM/yyyy')}</span>`
            }
          },
          {
            title: 'Payment Mode',
            'data': 'mode'
          },
          {
            title: 'Bank Name',
            'data': 'bankName'
          },
          {
            title: 'Cheque No',
            'data': 'chequeNumber'
          },
          {
            title: 'Amount (Rs.)',
            'data': 'amount'
          }
        ],
        footerCallback: function ( row, data, start, end, display ) {
          let api = this.api();
          let pageTotal = api
            .column( 4, { page: 'current'} )
            .data()
            .reduce( function (a, b) {
              return a + b;
            }, 0 );

          // Update footer
          $( api.column( 4 ).footer() ).html(
            pageTotal
          );
        }
      });
    }
  }

  private formInit() {
    let date = new Date();
    let chequeNumber = '';
    let bankName = '';
    let amount:number;
    let mode = 'Cash';

    this.addPayment = new FormGroup({
      'date': new FormControl(date, Validators.required),
      'bankName': new FormControl(bankName),
      'mode': new FormControl(mode),
      'amount': new FormControl(amount, Validators.required),
      'chequeNumber': new FormControl(chequeNumber)
    });
  }

}
