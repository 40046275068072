import {Component, OnInit} from '@angular/core';
import * as firebase from 'firebase/app';
import 'firebase/auth';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
  ngOnInit() {
    var config = {
      apiKey: "AIzaSyArlfVX43UcvKXRN3if-jYjOf7_KfGlvt8",
      authDomain: "jantatransportservices.firebaseapp.com",
      databaseURL: "https://jantatransportservices.firebaseio.com",
      projectId: "jantatransportservices",
      storageBucket: "jantatransportservices.appspot.com",
      messagingSenderId: "77158393953"
    };
    firebase.initializeApp(config);
  }
}
