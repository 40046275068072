import {Component, OnInit, QueryList, ViewChildren} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {DataStorageService} from '../Utils/data-storage.service';
import {SharedDataService} from '../Utils/sharedData.service';
import {BillStorageService} from '../Utils/bill-storage.service';
import {BillModal} from '../Utils/bill.modal';

import * as _ from 'underscore';
import {PaymentStorageService} from '../Utils/payment-storage.service';

declare const pdfMake;

var html2canvas = require('html2canvas');
import * as jsPDF from 'jspdf'

@Component({
  selector: 'app-print-bill',
  templateUrl: './print-bill.component.html',
  styleUrls: ['./print-bill.component.css']
})
export class PrintBillComponent implements OnInit {
  updateAdvance: boolean = false;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private dataService: DataStorageService,
              private sharedDataService: SharedDataService,
              private paymentService: PaymentStorageService,
              private billService: BillStorageService) {
  }

  data;

  billPrinted: any = [];
  billDate = new Date();
  billYear = this.billDate.getFullYear();
  billNo;
  public partyName = '';
  pages = [];
  totalBills = 0;
  LRNos = [];
  startDate = [];
  endDate = [];
  id;
  LRNo;
  printMode = false;
  display = false;
  advanceAmount = 0;

  @ViewChildren('billDiv') things: QueryList<any>;

  ngOnInit() {

    this.route.params
      .subscribe(
        (params: Params) => {
          this.id = params['id'];
          this.LRNo = params['lrno'];
          this.printMode = params['id'] != null;
          if (this.printMode) this.generateBill();
          else this.getInitialData();
        }
      );
  }

  generateBill() {
    this.billService.getBill(this.id, this.LRNo)
      .subscribe(
        (response) => {
          let data = response[0];
          this.billNo = data.billNo;
          this.billDate = data.billDate;
          this.partyName = data.partyName;
          let LRNos = data.LRNos;
          this.dataService.getGeneratedEntries(LRNos, this.billNo)
            .subscribe(
              (response) => {
                this.billPrinted = response;
                this.setBill();
              }, (err) => {
                console.log(err);
                alert('Error Occurred');
              }
            );
        }, (err) => {
          console.log(err);
          alert('Error Occurred');
        }
      );
  }

  getInitialData() {

    this.data = this.sharedDataService.getData();

    this.display = true;

    this.partyName = this.data.name;

    this.dataService.getNonGeneratedEntries(this.partyName, this.data.range.startDate, this.data.range.endDate)
      .subscribe(
        (response) => {
          this.billPrinted = response;
          if (!this.billPrinted.length) {
            alert('No Unbilled Entry found..');
            this.returnToGenerate();
          }
          this.billPrinted = response;
        }
      );
  }

  setBill() {
    this.paymentService.getAdvance(this.partyName)
      .subscribe(
        (result) => {
          this.advanceAmount = result[0] ? result[0].advanceAmount : 0;
          if (this.advanceAmount > 0) this.updateAdvance = true;
          let len = this.billPrinted.length;
          if (len > 20) {
            this.totalBills = len % 20 ? Math.floor(len / 20) : Math.floor(len / 20) - 1;
          }
          for (let index = 0; index <= this.totalBills; index++) {
            this.pages[index] = [];
            this.pages[index].billNo = this.billNo++;
            this.pages[index].billPrinted = this.billPrinted.slice(index * 20, (index * 20) + 20);
            this.pages[index].billPrinted = _.sortBy(this.pages[index].billPrinted, 'date');
            this.pages[index].totalBill = 0;
            this.LRNos[index] = [];
            for (let bill of this.pages[index].billPrinted) {
              this.pages[index].totalBill += bill.amount;
              this.LRNos[index].push(bill.LRNo);
            }
            if (this.advanceAmount > 0) {
              if (this.advanceAmount > this.pages[index].totalBill) {
                this.pages[index].advanceAmount = this.pages[index].totalBill;
                this.advanceAmount -= this.pages[index].totalBill;
                this.pages[index].balanceAmount = 0;
              } else {
                this.pages[index].advanceAmount = this.advanceAmount;
                this.advanceAmount = 0;
                this.pages[index].balanceAmount = this.pages[index].totalBill - this.pages[index].advanceAmount;
              }
            } else {
              this.pages[index].advanceAmount = 0;
              this.pages[index].balanceAmount = this.pages[index].totalBill;
            }
          }

          if (this.pages[this.totalBills].billPrinted.length < 20) {
            let dummyObject = {
              name: '', item: '', LRNo: '', area: '',
              weight: '', rate: '', amount: 0, date: '', _id: ''
            };
            for (let i = this.pages[this.totalBills].billPrinted.length; i < 20; i++) {
              this.pages[this.totalBills].billPrinted.push(dummyObject);
            }
          }
        }
      );
  }

  downloadPDf() {
    if (this.printMode) {
      let divName = 'printDiv0';
      html2canvas(document.getElementById(divName)).then((canvas) => {
        this.partyName = this.partyName.replace(/\s/g, '_');
        const filename = `${this.partyName}_${this.pages[0].billNo}.pdf`;
        const imgWidth = 208;
        const imgHeight = 300/*canvas.height * imgWidth / canvas.width*/;
        const contentDataURL = canvas.toDataURL('image/png');
        let pdf = new jsPDF('p', 'mm', 'a4'); // A4 size page of PDF
        const position = 0;
        pdf.addImage(contentDataURL, 'img', 0, position, imgWidth, imgHeight, undefined, 'MEDIUM');
        pdf.save(filename); // Generated PDF


        this.returnToGenerate();
      });
    } else {
      for (let i = 0; i <= this.totalBills; i++) {
        let bill: BillModal = {
          billYear: this.billYear,
          billDate: this.billDate,
          billNo: this.pages[i].billNo,
          totalAmount: this.pages[i].totalBill,
          advanceAmount: this.pages[i].advanceAmount || 0,
          balanceAmount: this.pages[i].balanceAmount,
          waivedOff: 0,
          partyName: this.partyName,
          LRNos: this.LRNos[i]
        };
        this.billService.storeBill(bill)
          .subscribe(
            (response) => {
              this.dataService.updateBillNo(this.partyName, bill.billNo, bill.LRNos)
                .subscribe(
                  (response) => {
                    if (this.updateAdvance) {
                      this.paymentService.updateAdvance(this.partyName, this.advanceAmount)
                        .subscribe(
                          (result) => {
                            let divName = 'printDiv' + i;
                            html2canvas(document.getElementById(divName)).then((canvas) => {
                              this.partyName = this.partyName.replace(/\s/g, '_');
                              const filename = `${this.partyName}_${this.pages[i].billNo}.pdf`;
                              const imgWidth = 208;
                              const imgHeight = 300/*canvas.height * imgWidth / canvas.width*/;
                              const contentDataURL = canvas.toDataURL('image/png');
                              let pdf = new jsPDF('p', 'mm', 'a4'); // A4 size page of PDF
                              const position = 0;
                              pdf.addImage(contentDataURL, 'img', 0, position, imgWidth, imgHeight, undefined, 'FAST');
                              pdf.save(filename); // Generated PDF

                              if (!this.totalBills || i === this.totalBills - 1) this.returnToGenerate();
                            });
                          });
                    } else {
                      let divName = 'printDiv' + i;
                      html2canvas(document.getElementById(divName)).then((canvas) => {
                        this.partyName = this.partyName.replace(/\s/g, '_');
                        const filename = `${this.partyName}_${this.pages[i].billNo}.pdf`;
                        const imgWidth = 208;
                        const imgHeight = 300/*canvas.height * imgWidth / canvas.width*/;
                        const contentDataURL = canvas.toDataURL('image/png');
                        let pdf = new jsPDF('p', 'mm', 'a4'); // A4 size page of PDF
                        const position = 0;
                        pdf.addImage(contentDataURL, 'img', 0, position, imgWidth, imgHeight, undefined, 'FAST');
                        pdf.save(filename); // Generated PDF


                        if (!this.totalBills || i === this.totalBills - 1) this.returnToGenerate();
                      });
                    }
                  }, (err) => {
                    this.billService.deleteBill(bill.billNo);

                  });
            }, (err) => {
              alert(err);
            });
      }
    }
  }

  closeDialog() {
    this.billService.getTotalBill(this.billDate)
      .subscribe(
        (response) => {
          this.billNo = response;
          this.billNo = parseInt(this.billNo);
          this.billNo++;
          this.setBill();
          this.display = false;
        }
      );
  }

  returnToGenerate() {
    if (this.printMode) this.router.navigate(['../../../', 'view'], {relativeTo: this.route});
    else this.router.navigate(['../', 'generate'], {relativeTo: this.route});
  }
}
