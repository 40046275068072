import {Injectable} from '@angular/core';
import {AuthService} from '../Core/auth/auth.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment';

@Injectable()
export class PaymentStorageService {

  apiUrl = environment.apiUrl;

  constructor(private authService: AuthService,
              private http: HttpClient) {}

  addPayment(name: string, paymentDetails: any, advanceAmount?: number) {
    if(this.authService.isAuthenticated()) {
      return this.http.put(this.apiUrl + 'payments/addPayments', {name, paymentDetails, advanceAmount});
    }
  }

  getAdvance(name: string) {
    if(this.authService.isAuthenticated()) {
      let params = new HttpParams();
      params = params.append('name', name);
      return this.http.get(this.apiUrl + 'payments/advance', {params: params});
    }
  }

  getPayments(name: string) {
    if(this.authService.isAuthenticated()) {
      let params = new HttpParams();
      params = params.append('name', name);
      return this.http.get(this.apiUrl + 'payments/payments', {params: params});
    }
  }

  getAllPayments(startDate: string, endDate: string) {
    if(this.authService.isAuthenticated()) {
      let params = new HttpParams();
      params = params.append('startDate', startDate);
      params = params.append('endDate', endDate);
      return this.http.get(this.apiUrl + 'payments/allPayments', {params: params});
    }
  }

  updateAdvance(name: string, advanceAmount?: number) {
    if(this.authService.isAuthenticated()) {
      return this.http.put(this.apiUrl + 'payments/updateAdvance', {name, advanceAmount});
    }
  }
}
