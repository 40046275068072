import {Injectable} from "@angular/core";
import {AuthService} from "../Core/auth/auth.service";
import {HttpClient, HttpParams} from '@angular/common/http';
import {EntryModal} from './entry.modal';
import {environment} from '../../environments/environment';

@Injectable()
export class DataStorageService {

  apiUrl = environment.apiUrl;

  constructor(private http:HttpClient,
              private authService: AuthService) {}

  storeEntry(entry: EntryModal) {
    if(this.authService.isAuthenticated()) {
      return this.http.post(this.apiUrl + 'recordEntry/entry', entry);
    }
  }

  getEntries(name: string, lrno: string, dateRangeOption: string, startDate?: string, endDate?: string) {
    if(this.authService.isAuthenticated()) {
      let params = new HttpParams();
      params = name ? params.append('name', name) : params.append('lrno', lrno);
      /*params = params.append('name', name);
      params = params.append('lrno', lrno);*/
      params = params.append('dateRangeOption', dateRangeOption);
      if(dateRangeOption === '5') {
        params = params.append('startDate', startDate);
        params = params.append('endDate', endDate);
      }
      return this.http.get(this.apiUrl + 'recordEntry/entries', {params: params});
    }
  }

  getNonGeneratedEntries(name: string, startDate?: string, endDate?: string) {
    if(this.authService.isAuthenticated()) {
      let params = new HttpParams();
      params = params.append('name', name);
      params = params.append('dateRangeOption', '5');
      params = params.append('startDate', startDate);
      params = params.append('endDate', endDate);
      return this.http.get(this.apiUrl + 'recordEntry/nonGeneratedEntries', {params: params});
    }
  }

  getUnpaidLRFullDetails() {
    if(this.authService.isAuthenticated()) {
      let params = new HttpParams();
      return this.http.get(this.apiUrl + 'recordEntry/nonGeneratedLRs');
    }
  }

  getPaidOnBooking(startDate: string, endDate: string) {
    if(this.authService.isAuthenticated()) {
      let params = new HttpParams();
      params = params.append('startDate', startDate);
      params = params.append('endDate', endDate);
      return this.http.get(this.apiUrl + 'recordEntry/paidOnBooking', {params: params});
    }
  }

  nonGeneratedEntriesAmount(name: string) {
    if(this.authService.isAuthenticated()) {
      let params = new HttpParams();
      params = params.append('name', name);
      return this.http.get(this.apiUrl + 'recordEntry/nonGeneratedEntriesAmount', {params: params});
    }
  }

  getGeneratedEntries(LRNos: any, billNo: number) {
    if(this.authService.isAuthenticated()) {
      let params = new HttpParams();
      params = params.append('LRNos', LRNos);
      params = params.append('billNo', billNo.toString());
      return this.http.get(this.apiUrl + 'recordEntry/generatedBill', {params: params});
    }
  }


  getNames() {
    if(this.authService.isAuthenticated()) {
      return this.http.get(this.apiUrl + 'recordEntry/partyNames');
    }
  }

  getEntry(id: any) {
    if(this.authService.isAuthenticated()) {
      let params = new HttpParams();
      params = params.append('id', id);
      return this.http.get(this.apiUrl + 'recordEntry/entry', {params: params});
    }
  }

  updateEntry(id: string, entry: EntryModal) {
    if(this.authService.isAuthenticated()) {
      return this.http.put(this.apiUrl + 'recordEntry/entry', {id, entry});
    }
  }

  updateBillNo(name: string, billNo: number, LRNos: any) {
    if(this.authService.isAuthenticated()) {
      return this.http.put(this.apiUrl + 'recordEntry/updateBillNo', {name, billNo, LRNos});
    }
  }

  deleteEntry(id: string) {
    if(this.authService.isAuthenticated()) {
      let params = new HttpParams();
      params = params.append('id', id);
      return this.http.delete(this.apiUrl + 'recordEntry/entry', {params: params});
    }
  }

  getDailyReport(date: string, endDate: string) {
    if(true) {
      let params = new HttpParams();
      params = params.append('date', date);
      params = params.append('endDate', endDate);
      return this.http.get(this.apiUrl + 'recordEntry/dailyReport', {params: params});
    }
  }

  getDailyCashReport(date: string, endDate: string) {
    if(true) {
      let params = new HttpParams();
      params = params.append('date', date);
      params = params.append('endDate', endDate);
      return this.http.get(this.apiUrl + 'recordEntry/dailyCashReport', {params: params});
    }
  }
}
