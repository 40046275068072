import {NgModule} from "@angular/core";
import {RouterModule, Routes} from "@angular/router";
import {AddChallanComponent} from "./add-challan/add-challan.component";
import {ViewBillComponent} from "./view-bill/view-bill.component";
import {LoginComponent} from "./Core/auth/login/login.component";
import {GenerateBillComponent} from "./generate-bill/generate-bill.component";
import {PrintBillComponent} from "./print-bill/print-bill.component";
import {AuthGaurdService} from "./Core/auth/authGaurd.service";
import {ViewBalanceComponent} from "./view-balance/view-balance.component";
import {HomeComponent} from "./Core/home/home.component";
import {PendingBillComponent} from './pending-bill/pending-bill.component';
import {DailyReportComponent} from './daily-report/daily-report.component';
import {PrintReportComponent} from './print-report/print-report.component';
import {DailyCashReportComponent} from "./daily-cash-report/daily-cash-report.component";

const appRoutes : Routes =[
  {path: '' ,redirectTo:"/home", pathMatch:"full"},
  {path: 'home' , component: HomeComponent},
  {path: 'add' , component: AddChallanComponent, canActivate: [AuthGaurdService]},
  {path: 'view' , component: ViewBillComponent, canActivate: [AuthGaurdService]},
  {path: 'add/:id' , component: AddChallanComponent, canActivate: [AuthGaurdService]},
  {path: 'signIn', component: LoginComponent},
  {path: 'generate', component: GenerateBillComponent, canActivate: [AuthGaurdService]},
  {path: 'print', component: PrintBillComponent, canActivate: [AuthGaurdService]},
  {path: 'dailyreport', component: DailyReportComponent, canActivate: [AuthGaurdService]},
  {path: 'dailycashreport', component: DailyCashReportComponent, canActivate: [AuthGaurdService]},
  {path: 'printreport/:date/:endDate', component: PrintReportComponent},
  {path: 'print/:id/:lrno', component: PrintBillComponent, canActivate: [AuthGaurdService]},
  {path: 'balance', component: ViewBalanceComponent, canActivate: [AuthGaurdService]},
  {path: 'bills', component: PendingBillComponent, canActivate: [AuthGaurdService]},
  {path: '**', redirectTo:'/home'}
];

@NgModule ({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule]
})

export class AppRouterModule{

}
