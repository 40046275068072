import {Component, OnInit, ViewChild} from '@angular/core';
import {DataStorageService} from '../Utils/data-storage.service';

import {Router} from '@angular/router';
import {ExpenseStorageService} from '../Utils/expense-storage.service';
import {ExpenseModal} from '../Utils/expense.modal';
import {PaymentStorageService} from '../Utils/payment-storage.service';

@Component({
  selector: 'app-daily-cash-report',
  templateUrl: './daily-cash-report.component.html',
  styleUrls: ['./daily-cash-report.component.css']
})
export class DailyCashReportComponent implements OnInit {

  maxDate = new Date();
  dailyReports = [];
  dailyBillPayment = [];
  model: any = {};
  showTable = false;
  totalAmount = 0;
  pendingCashAmount = 0;
  pendingChequeAmount = 0;
  cashAmount = 0;
  billAmount = 0;
  cashLRAmount = 0;
  chequeLRAmount = 0;
  cashBillAmount = 0;
  chequeBillAmount = 0;
  reportGeneateDate;
  dieselAmount = 0;
  miscAmount = 0;
  loadAmount = 0;
  balanceAmount = 0;
  expenseUpdate = false;
  showExpenseForm = false;
  id = '';

  @ViewChild('reportDate') reportDate: any;
  @ViewChild('expense') expense: any;
  endDate;

  constructor(private dataStorageService: DataStorageService,
              private router: Router,
              private paymentStorageService: PaymentStorageService,
              private expenseStorageService: ExpenseStorageService) { }

  ngOnInit() {
  }

  enableExpenseForm() {
    this.model = {
      'diesel': this.dieselAmount,
      'misc': this.miscAmount,
      'load': this.loadAmount
    };
    this.showExpenseForm = true;
  }

  onExpenseSubmit() {
    this.loadAmount = this.model.load;
    this.miscAmount = this.model.misc;
    this.dieselAmount = this.model.diesel;
    this.balanceAmount = this.totalAmount - (this.dieselAmount + this.dieselAmount + this.loadAmount);
    const date = this.endDate;
    date.setDate(this.endDate.getDate() - 1);
    date.setHours(0,0,0,0);
    const expenseDate = date.toISOString();
    const obj: ExpenseModal = {
      date: expenseDate,
      Diesel: this.model.diesel,
      load: this.model.load,
      Misc: this.model.misc
    };
    if (this.expenseUpdate) {
      this.expenseStorageService.updateExpense(obj,this.id)
        .subscribe(res => {
          this.showExpenseForm = false;
        });
    } else {
      this.expenseStorageService.storeExpense(obj)
        .subscribe(res => {
          this.showExpenseForm = false;
        })
    }
  }

  getColor(date) {
    if(new Date(this.reportDate.nativeElement.value).getTime() > new Date(date).getTime()) return 'red';
    return 'green';
  }


  onSubmit() {
    this.expenseUpdate = false;
    this.endDate = new Date(this.reportDate.nativeElement.value);
    let date = this.endDate;
    // date.setDate(this.endDate.getDate());
    date.setHours(0,0,0,0);
    const expenseDate = date.toISOString();
    this.endDate.setDate(this.endDate.getDate() + 1);
    this.dataStorageService.getDailyCashReport(expenseDate, this.endDate)
      .subscribe((entries: Array<any>) => {
        this.paymentStorageService.getAllPayments(expenseDate, this.endDate)
          .subscribe((payments: Array<any>) => {
            this.expenseStorageService.getExpense(expenseDate)
              .subscribe((expense: Array<any>) => {
                this.billAmount = 0;
                this.totalAmount = 0;
                /*this.pendingCashAmount = 0;
                this.pendingChequeAmount = 0;
                this.cashAmount = 0;
                this.billAmount = 0;
                this.chequeBillAmount = 0;*/
                this.cashLRAmount = 0;
                this.chequeLRAmount = 0;
                this.dailyReports = [];
                this.dailyBillPayment = [];
                this.reportGeneateDate = this.reportDate.nativeElement.value;
                if (expense[0]) {
                  this.expenseUpdate = true;
                  this.id =  expense[0]['_id'];
                }
                this.miscAmount = expense[0] ? expense[0]['Misc'] : 0;
                this.loadAmount = expense[0] ? expense[0]['load'] : 0;
                this.dieselAmount = expense[0] ? expense[0]['Diesel'] : 0;
                this.balanceAmount = 0;
                entries.sort((a,b) => a.LRNo - b.LRNo);
                payments.map(payment => {
                  payment.paymentDetails.map(details => {
                    if (new Date(details.date) >= new Date(this.reportGeneateDate) && new Date(details.date) < new Date(this.endDate)) {
                      entries.push({
                        paymentDetails: {
                          mode: details.mode,
                          bankName: details.bankName,
                          chequeNumber: details.chequeNumber
                        },
                        name: payment.name,
                        amount: details.amount,
                        date: details.date
                      });
                      this.billAmount += details.amount;
                    }
                  })
                });
                entries.forEach(report => {
                  if (report.paymentDetails.mode === 'Cash') {
                    report.mode = 'Cash';
                  } else {
                    report.mode = `Cheque (${report.paymentDetails.bankName} - ${report.paymentDetails.chequeNumber})`;
                  }
                  this.dailyReports.push(report);
                  if (report.LRNo) {
                    if (new Date(this.reportDate.nativeElement.value).getTime() > new Date(report.date).getTime()) {
                      // this.pendingCashAmount += report.amount;

                      report.bgColor = 'red';
                    } else {
                      // this.cashAmount += report.amount;
                      /* if (report.paymentDetails.mode === 'Cash') {
                        this.cashLRAmount += report.amount;
                      } else {
                        this.chequeLRAmount += report.amount;
                      }*/
                      report.bgColor = 'green';
                    }
                    if (report.paymentDetails.mode === 'Cash') {
                      this.cashLRAmount += report.amount;
                    } else {
                      this.chequeLRAmount += report.amount;
                    }
                    this.totalAmount += report.amount;
                  }
                });
                this.totalAmount += this.billAmount;
                this.balanceAmount = this.totalAmount - (this.dieselAmount + this.miscAmount + this.loadAmount);
                this.showTable = true;
              });
          });
      });
  }
}
