import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {AddChallanComponent} from './add-challan/add-challan.component';
import {ViewBillComponent} from './view-bill/view-bill.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CoreModule} from './Core/core.module';
import {DataTablesModule} from 'angular-datatables';
import {HttpClientModule} from '@angular/common/http';
import {DatePipe} from '@angular/common';
import {NgHttpLoaderModule} from 'ng-http-loader';
import {GenerateBillComponent} from './generate-bill/generate-bill.component';
import {PrintBillComponent} from './print-bill/print-bill.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ConfirmationService, ConfirmDialogModule, DialogModule, SharedModule} from 'primeng/primeng';
import {ViewBalanceComponent} from './view-balance/view-balance.component';
import {TypeaheadModule} from 'ngx-bootstrap/typeahead';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import {HomeComponent} from './Core/home/home.component';
import {TabsModule} from 'ngx-bootstrap/tabs';
import {PendingBillComponent} from './pending-bill/pending-bill.component';
import {SimpleNotificationsModule} from 'angular2-notifications';
import {DailyReportComponent} from './daily-report/daily-report.component';
import {PrintReportComponent} from './print-report/print-report.component';
import {DailyCashReportComponent} from './daily-cash-report/daily-cash-report.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AddChallanComponent,
    ViewBillComponent,
    GenerateBillComponent,
    PrintBillComponent,
    ViewBalanceComponent,
    PendingBillComponent,
    DailyReportComponent,
    DailyCashReportComponent,
    PrintReportComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgHttpLoaderModule.forRoot(),
    CoreModule,
    DataTablesModule,
    ConfirmDialogModule,
    DialogModule,
    SharedModule,
    TypeaheadModule.forRoot(),
    BsDatepickerModule.forRoot(),
    TabsModule.forRoot(),
    SimpleNotificationsModule.forRoot()
  ],
  providers: [DatePipe, ConfirmationService],
  bootstrap: [AppComponent]
})
export class AppModule {
}
