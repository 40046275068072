import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {DataStorageService} from '../Utils/data-storage.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {NotificationsService} from 'angular2-notifications';

@Component({
  selector: 'app-add-challan',
  templateUrl: './add-challan.component.html',
  styleUrls: ['./add-challan.component.css']
})
export class AddChallanComponent implements OnInit {

  addForm: FormGroup;
  id: string;
  editMode = false;
  display = false;
  bills;
  names;
  weightEntered: number;
  rateEntered: number;
  totalAmount: number;
  disableAmount = false;
  maxDate = new Date();

  public options = {
    position: ['bottom', 'right'],
    timeOut: 1000,
    lastOnBottom: true
  };

  pTypes = ['Cash', 'Cheque'];
  billTypes = ['Party', 'Individual'];

  constructor(private dataStorageService: DataStorageService,
              private route: ActivatedRoute,
              private router: Router,
              private _service: NotificationsService) {
  }

  ngOnInit() {
    this.dataStorageService.getNames()
      .subscribe(
        (response) => {
          this.names = response;
        }, (err) => {
          console.log(err);
        }
      );
    this.route.params
      .subscribe(
        (params: Params) => {
          this.id = params['id'];
          this.editMode = params['id'] != null;
          this.formInit();
        }
      );
  }

  items = ['Angle', 'Flate', 'Channel', 'Beam', 'Plate', 'Round', 'Bright', 'Sheet', 'Profile',
    'Bag', 'Pipes', 'Ring', 'Circle', 'Square Cutting'];

  areas = ['Chembur', 'Govandi', 'Ghatkopar', 'Vikhroli', 'Kanjurmarg', 'Bhandup', 'Mulund', 'Thane', 'Bhiwandi', 'Airoli',
    'Kalwa', 'Rabale', 'Mahape', 'Khairne', 'Pawane', 'Turbhe', 'Nerul', 'Taloja', 'Ambarnath', 'Dombivali', 'Santacruz',
    'Kurla', 'Narayan Nagar', 'Jarimari', 'Andheri', 'Khairani Road', 'Goregaon', 'Jogeshwari', 'Malad', 'Kandivali',
    'Dahisar', 'Kashi Mira', 'Ghodbandar', 'Naya Gaon', 'Vasai', 'Kaman', 'Palghar', 'Nalasopara', 'Murbad', 'Digha', 'Virar',
    'Bhayander', 'Naigaon', 'Badlapur'];

  onCancel() {
    this.router.navigate(['./', 'view'], {relativeTo: this.route.parent});
  }

  adjustForTimezone(date: Date): Date {
    let timeOffsetInMS: number = date.getTimezoneOffset() * 60000;
    date.setTime(date.getTime() - timeOffsetInMS);
    return date;
  }

  onSubmit() {
    if (this.addForm.valid) {
      this.addForm.value.rate = this.addForm.value.rate ? this.addForm.value.rate : 0;
      this.addForm.value.billNo = this.addForm.value.paid ? -1 : 0;
      this.addForm.value.billGenerated = this.addForm.value.paid ? true : false;
      this.addForm.value.name = this.addForm.value.name.toUpperCase().trim();
      this.addForm.value.item = this.addForm.value.item.toUpperCase().trim();
      this.addForm.value.area = this.addForm.value.area.toUpperCase().trim();
      if (this.addForm.value.billGenerated) {
        this.addForm.value.paymentDetails = {
          paymentDate: this.addForm.value.paymentDate,
          mode: this.addForm.value.mode,
          chequeNumber: this.addForm.value.chequeNumber.trim(),
          bankName: this.addForm.value.bankName.toUpperCase().trim()
        };
      } else {
        this.addForm.value.paymentDetails = {
          paymentDate: this.addForm.value.date,
          mode: 'Cash',
          chequeNumber: '',
          bankName: ''
        };
      }
      if (this.editMode) {
        this.dataStorageService.updateEntry(this.id, this.addForm.value)
          .subscribe(
            (response) => {
              this.onCancel();
            }
          );
      } else {
        this.dataStorageService.storeEntry(this.addForm.value)
          .subscribe(
            (response) => {
              this._service.success(
                'Message',
                'Added Successfully',
                {
                  timeOut: 1000,
                  showProgressBar: true,
                  pauseOnHover: false,
                  clickToClose: false,
                  maxLength: 10
                }
              );
            }, (err) => {
              this._service.error(
                'Message',
                err.message,
                {
                  timeOut: 1000,
                  showProgressBar: true,
                  pauseOnHover: false,
                  clickToClose: false,
                  maxLength: 10
                }
              );
            }
          );
        if (this.names.indexOf(this.addForm.value.name) === -1) {
          this.names.push(this.addForm.value.name);
        }
        this.addForm.reset();
      }
    }
    else this.display = true;
  }

  postData() {
    if (this.addForm.valid) {
      this.onSubmit();
      this.onCancel();
    }
    else this.display = true;

  }

  calculateAmount() {
    this.totalAmount = Math.ceil(this.rateEntered * this.weightEntered);
    this.disableAmount = !!(this.rateEntered && this.weightEntered);
  }

  private formInit() {

    let date = new Date();
    let LRNo;
    let name = '';
    let amount;
    let rate;
    let area = '';
    let item = '';
    let weight;
    let billNo = 0;
    let billGenerated = false;
    let paid;
    let paymentDate = new Date();
    let chequeNumber = '';
    let bankName = '';
    let mode = 'Cash';
    let billType = 'Party';


    if (this.editMode) {
      this.dataStorageService.getEntry(this.id)
        .subscribe(
          (response) => {
            const bill = response[0];
            date = new Date(bill.date);
            LRNo = bill.LRNo;
            amount = bill.amount;
            rate = bill.rate;
            area = bill.area;
            item = bill.item;
            weight = bill.weight;
            name = bill.name;
            billNo = bill.billNo;
            billGenerated = bill.billGenerated;
            paid = bill.paid;
            billType = bill.billType;
            if (bill.paymentDetails) {
              paymentDate = new Date(bill.paymentDetails.paymentDate);
              chequeNumber = bill.paymentDetails.chequeNumber;
              bankName = bill.paymentDetails.bankName;
              mode = bill.paymentDetails.mode;
            } else {
              paymentDate = new Date(bill.date);
              mode = 'Cash';
              chequeNumber = '';
              bankName = '';
            }
            this.weightEntered = parseInt(weight);
            this.rateEntered = parseInt(rate);
            this.totalAmount = parseInt(amount);
            this.disableAmount = !!(this.rateEntered && this.weightEntered);

            this.addForm = new FormGroup({
              'date': new FormControl(date),
              'LRNo': new FormControl(LRNo, Validators.required),
              'name': new FormControl(name, Validators.required),
              'amount': new FormControl(amount, Validators.required),
              'rate': new FormControl(rate),
              'area': new FormControl(area, Validators.required),
              'item': new FormControl(item, Validators.required),
              'weight': new FormControl(weight, Validators.required),
              'billNo': new FormControl(billNo),
              'billGenerated': new FormControl(billGenerated),
              'paid': new FormControl(paid),
              'paymentDate': new FormControl(paymentDate),
              'chequeNumber': new FormControl(chequeNumber),
              'bankName': new FormControl(bankName),
              'mode': new FormControl(mode),
              'billType': new FormControl(billType)
            });
          }, (err) => {
            this._service.error(
              'Message',
              err.message,
              {
                timeOut: 1000,
                showProgressBar: true,
                pauseOnHover: false,
                clickToClose: false,
                maxLength: 10
              }
            );
          }
        );
    }

    this.addForm = new FormGroup({
      'date': new FormControl(date, Validators.required),
      'LRNo': new FormControl(LRNo, Validators.required),
      'name': new FormControl(name, Validators.required),
      'amount': new FormControl(amount, Validators.required),
      'rate': new FormControl(rate),
      'area': new FormControl(area, Validators.required),
      'item': new FormControl(item, Validators.required),
      'weight': new FormControl(weight, Validators.required),
      'billNo': new FormControl(billNo),
      'billGenerated': new FormControl(billGenerated),
      'paid': new FormControl(paid),
      'paymentDate': new FormControl(paymentDate),
      'chequeNumber': new FormControl(chequeNumber),
      'bankName': new FormControl(bankName),
      'mode': new FormControl(mode),
      'billType': new FormControl(billType)
    });
  }
}
