import {Component, OnInit, ViewChild} from '@angular/core';
import {EntryModal} from "../Utils/entry.modal";
import {DatePipe} from "@angular/common";
import {DataStorageService} from "../Utils/data-storage.service";
import {ActivatedRoute, Router} from "@angular/router";
import {ConfirmationService} from "primeng/primeng";
import {NotificationsService} from 'angular2-notifications';

var $ = require('jquery');
var dt = require('datatables.net');

@Component({
  selector: 'app-view-bill',
  templateUrl: './view-bill.component.html',
  styleUrls: ['./view-bill.component.css']
})
export class ViewBillComponent implements OnInit {

  public bills : EntryModal[] = [];
  public table = $('#billTable').DataTable();
  showTable = false;
  showCalendar = false;
  showPartyName = false;
  names;
  filteredBills = [];
  maxDate = new Date();
  bsRangeValue;

  public options = {
    position: ["bottom", "right"],
    timeOut: 1000,
    lastOnBottom: true
  };
  partyName = '';

  billViewOptions = [
    { 'id': 1, 'value': 'All'},
    { 'id': 2, 'value': 'Today'},
    { 'id': 3, 'value': 'Current Month'},
    { 'id': 4, 'value': 'Last Month & Current Month'},
    { 'id': 5, 'value': 'Select Time Period'},
  ];

  @ViewChild('filter') filter:any;
  @ViewChild('pname') pname:any;
  @ViewChild('lrno') lrno:any;

  constructor(private dataStorageService: DataStorageService,
              private route:ActivatedRoute,
              private router: Router,
              private confirmationService: ConfirmationService,
              private _service: NotificationsService) { }

  ngOnInit() {
    this.dataStorageService.getNames()
      .subscribe(
        (response) => {
          this.names = response;
          this.names.sort();
        }, (err) => {
          console.log(err);
        }
      );
  }

  filterChanged() {
    this.showCalendar = this.filter.nativeElement.value === "5";
  }

  filterResults() {
    let index = this.filter.nativeElement.value;
    let filterName = this.pname.nativeElement.value;
    let lrno = this.lrno.nativeElement.value;
    if(index != '5') {
      this.dataStorageService.getEntries(filterName, lrno, index)
        .subscribe(
          (response) => {
            this.drawTable(response);
          }

        )
    } else {
      this.dataStorageService.getEntries(filterName, lrno, index, this.bsRangeValue[0].valueOf(), this.bsRangeValue[1].valueOf())
        .subscribe(
          (response) => {
            this.drawTable(response);
          }

        )
    }
  }

  deleteBill(index: string) {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to delete this bill ?',
      accept: () => {
        this.dataStorageService.deleteEntry(index)
          .subscribe(
            (response) => {
              let bills = this.filteredBills.filter((bill) => { return bill._id != index;})
              this.drawTable(bills);
            }, (err) => {
              this._service.error(
                'Message',
                err.message,
                {
                  timeOut: 1000,
                  showProgressBar: true,
                  pauseOnHover: false,
                  clickToClose: false,
                  maxLength: 10
                }
              )
            }
          );
      }
    });
  }

  drawTable(bills) {
    if (!bills.length) {
      this.showTable = false;
      this._service.warn(
        'Message',
        'No bills found',
        {
          timeOut: 1000,
          showProgressBar: true,
          pauseOnHover: false,
          clickToClose: false,
          maxLength: 10
        }
      )
    }
    else {
      this.partyName = bills[0].name;
      this.showPartyName = !!this.lrno.nativeElement.value;
      this.filteredBills = bills;
      this.table.destroy();
      $('#billTable').empty();
      this.showTable = true;
      this.table = $('#billTable').DataTable({
        "data": bills,
        "order": [[ 1, "desc" ]],
        "columns": [
          {
            title: "S. No",
            data: "_id",
            bSortable: false,
            width: "5%"
          },
          {
            title: "Date",
            "data": function (data) {
              return new Date(data.date);
            },
            "render": function (data) {
              return `<span style="display: none">${Date.parse(data)} </span><span>${new DatePipe('en-US').transform(data, 'dd/MM/yyyy')}</span>`
            }
          },
          {
            title: "LRNo",
            "data": "LRNo"
          },
          {
            title: "Area",
            "data": "area"
          },
          {
            title: "Particulars",
            "data": "item"
          },
          {
            title: "Weight (Kgs)",
            "data": "weight"
          },
          {
            title: "Rate (/Kg)",
            "data": "rate"
          },
          {
            title: "Amount (Rs.)",
            "data": "amount"
          },
          {
            title: "",
            "data": function (data) {
              if(data.billGenerated) {
                if(data.billNo === -1) {
                  if(data.paymentDetails) {
                    const pData = data.paymentDetails;
                    return pData.mode === 'Cash' ? `<span style="color: green">Paid Cash (${new DatePipe('en-US').transform(pData.paymentDate, 'dd/MM/yyyy')})</span>` :
                      `<span style="color: green">Paid Cheque (${new DatePipe('en-US').transform(pData.paymentDate, 'dd/MM/yyyy')}, ${pData.bankName}, ${pData.chequeNumber})</span>`;
                  }
                  return '<span style="color: green">Paid Cash</span>'
                }
                return '<a href="javascript:void(0)">Bill No: ' + data.billNo + '</a>'
              }
              return '<a href="javascript:void(0)">Edit</a> | <a href="javascript:void(0)">Delete</a>'
            },
            bSortable: false
          }
        ],
        rowCallback: (row: Node, data: EntryModal, index: number) => {
          $('td:eq(8) a:eq(0)', row).off('click');
          $('td:eq(8) a:eq(1)', row).off('click');
          $('td:eq(8) a:eq(0)', row).on('click', () => {
            if(data.billGenerated) {
              this.router.navigate(['../','print',data.billNo, data.LRNo], {relativeTo: this.route});
            }
            else {
              this.router.navigate(['../','add',data._id], {relativeTo: this.route});
            }
          });
          $('td:eq(8) a:eq(1)', row).on('click', () => {
            this.deleteBill(data._id);
          });
          $('td:eq(0)', row).html(index + 1)
          return row;
        },
        footerCallback: function ( row, data, start, end, display ) {
          let api = this.api();
          let pageTotal = api
            .column( 8, { page: 'current'} )
            .data()
            .reduce( function (a, b) {
              return a + b;
            }, 0 );

          // Update footer
          $( api.column( 8 ).footer() ).html(
            pageTotal
          );
        }
      });
    }
  }
}
